import {
	ActivityIndicator,
	Button,
	Dialog,
	DialogAction,
	DialogActions,
	DialogContent,
	Errors,
	Field,
	FieldDescription,
	Form,
	HiddenFields,
	Input,
	Label,
	Option,
	Select,
	TextField,
} from '@troon/ui';
import { useAction, useSubmission } from '@solidjs/router';
import { createEffect, createSignal, createUniqueId, Match, Show, Switch, For } from 'solid-js';
import { phone } from 'phone';
import { IconCircleCheck } from '@troon/icons/circle-check';
import { IconPhone } from '@troon/icons/phone';
import { countryCodes } from '@troon/countries';
import { gql, mutationAction, useMutation } from '../graphql';
import { useUser } from '../providers/user';
import type { JSXElement } from 'solid-js';

type Props = {
	buttonClass?: string;
	buttonLabel?: JSXElement;
	onCancel?: () => void;
	onSuccess?: (phoneNumber: string) => void;
};

export function RequestPhoneNumber(props: Props) {
	const user = useUser();
	const [form, setForm] = createSignal<HTMLFormElement>();
	const formId = createUniqueId();

	const updateAction = useMutation(requestUpdate);
	const resendAction = useMutation(requestUpdate);
	const resendRequest = useAction(resendAction);

	const confirmAction = useMutation(confirm);
	const [inputNumber, setInputNumber] = createSignal(user()?.me.phoneNumber ?? '');
	const [countryCode, setCountryCode] = createSignal<string>(
		user()?.me.phoneNumber ? (phone(user()!.me.phoneNumber!).countryIso2 ?? 'US') : 'US',
	);
	const [showConfirmation, setShowConfirmation] = createSignal(false);

	const updateSubmission = useSubmission(updateAction);
	const resendSubmission = useSubmission(resendAction, ([data]) => data.get('__formId') !== formId);
	const confirmSubmission = useSubmission(confirmAction);

	const [confirmed, setConfirmed] = createSignal(false);

	createEffect(() => {
		const data = updateSubmission.result?.data?.updatePhoneNumber;
		if (data?.requiresConfirmation) {
			setShowConfirmation(!confirmSubmission?.result?.data?.confirmPhoneNumber);
			return;
		}

		if (data && !data.requiresConfirmation && props.onSuccess) {
			setConfirmed(true);
			props.onSuccess(phone(inputNumber(), { country: countryCode() }).phoneNumber!);
		}
	});

	createEffect(() => {
		const phoneNumber = confirmSubmission?.result?.data?.confirmPhoneNumber.phoneNumber;
		if (phoneNumber && props.onSuccess) {
			props.onSuccess(phoneNumber);
			setConfirmed(true);
			setShowConfirmation(false);
		}
	});

	createEffect(() => {
		const check = phone(inputNumber());
		if (check.isValid && check.countryIso2 !== countryCode()) {
			setCountryCode(check.countryIso2);
		}
	});

	const displayNames = new Intl.DisplayNames('en-US', { type: 'region' });
	const compare = new Intl.Collator('en-US').compare;
	const countries = countryCodes
		.map((code) => [code, displayNames.of(code)] as [string, string])
		.sort((a, b) => compare(a[1], b[1]));

	return (
		<>
			<Form action={updateAction} document={requestMutation} ref={setForm} id={formId}>
				<div class="flex gap-4">
					<Field name="__countryCode" class="flex grow-0 basis-1/3 flex-col gap-1 sm:basis-1/4">
						<Label>Country</Label>
						<Select onChange={(e) => setCountryCode(e.target.value)}>
							<For each={countries}>
								{([code, name]) => (
									<Option value={code} selected={code === countryCode()}>
										{name}
									</Option>
								)}
							</For>
						</Select>
					</Field>
					<TextField name="input.phoneNumber" class="grow">
						<Label>Phone number</Label>
						<Input
							readonly={confirmed()}
							prefixElement={confirmed() ? <IconCircleCheck class="text-green-500" /> : <IconPhone />}
							autocomplete="tel"
							inputMode="tel"
							class="ps-8"
							onInput={(e) => setInputNumber(e.target.value)}
							value={user()?.me.phoneNumber ?? ''}
						/>
					</TextField>
				</div>

				<Errors />

				<Show when={!confirmed()}>
					<div class="flex gap-8">
						<Show when={props.onCancel}>
							<Button appearance="tertiary-current" onClick={props.onCancel} type="button" class="size-fit shrink">
								Cancel
							</Button>
						</Show>
						<Button
							type="submit"
							class={props.buttonClass}
							disabled={!phone(inputNumber(), { country: countryCode() }).isValid}
						>
							{props.buttonLabel || 'Save'}
						</Button>
					</div>
				</Show>
			</Form>
			<Dialog key="add-phone-number" open={showConfirmation()} onOpenChange={setShowConfirmation}>
				<DialogContent header="Confirm your phone number" headerLevel="h3">
					<Form action={confirmAction} document={confirmMutation} class="flex flex-col gap-4">
						<p>A confirmation code has been sent to {phone(inputNumber(), { country: countryCode() }).phoneNumber}.</p>
						<HiddenFields
							data={{ 'input.phoneNumber': phone(inputNumber(), { country: countryCode() }).phoneNumber }}
						/>
						<TextField name="input.confirmationCode">
							<Label>Confirmation Code</Label>
							<Input inputMode="numeric" autocomplete="one-time-code" />
							<FieldDescription>
								<p>
									Didn’t receive a code?{' '}
									<button
										type="button"
										class="inline-flex items-center text-brand underline"
										onClick={() => setShowConfirmation(false)}
									>
										Change your phone number
									</button>{' '}
									or{' '}
									<button
										type="button"
										disabled={resendSubmission.pending || !!resendSubmission.result?.data?.updatePhoneNumber}
										class="inline-flex items-center text-brand underline"
										onClick={() => {
											const data = new FormData(form());
											data.delete('__formId');
											resendRequest(data);
										}}
									>
										resend
									</button>
									<Switch>
										<Match when={resendSubmission.pending}>
											<span class="inline-flex">
												<ActivityIndicator class=" size-4" />
											</span>
										</Match>
										<Match when={resendSubmission.result?.data?.updatePhoneNumber}>
											<span>
												<IconCircleCheck class="size-4 text-green-500" />
												<span class="sr-only">Code resent</span>
											</span>
										</Match>
									</Switch>{' '}
									the confirmation code.
								</p>
							</FieldDescription>
						</TextField>

						<Errors />

						<DialogActions>
							<DialogAction type="submit">Confirm</DialogAction>
							<DialogAction type="button" appearance="transparent" onClick={() => setShowConfirmation(false)}>
								Cancel
							</DialogAction>
						</DialogActions>
					</Form>
				</DialogContent>
			</Dialog>
		</>
	);
}

const requestMutation = gql(`mutation updatePhoneNumber($input: UpdatePhoneNumberInput!) {
	updatePhoneNumber: updatePhoneNumberV2(input: $input) { requiresConfirmation }
}`);

const requestUpdate = mutationAction(requestMutation, {
	transform: (data) => ({
		input: {
			phoneNumber:
				phone(data.get('input.phoneNumber')! as string, { country: data.get('__countryCode') as string }).phoneNumber ??
				(data.get('input.phoneNumber') as string),
		},
	}),
	revalidates: ['loggedInUser'],
});

const confirmMutation = gql(`mutation confirmPhoneNumber($input: ConfirmPhoneNumberInput!) {
	confirmPhoneNumber(input: $input) { phoneNumber }
}`);

const confirm = mutationAction(confirmMutation, {
	revalidates: ['loggedInUser'],
});
